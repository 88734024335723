.lds-facebook {
    display: inline-block;
    position: relative;
    width: 26.67px; /* 80/3 */
    height: 21px; /* 80/3 */
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 2.67px; /* 8/3 */
    width: 5.33px; /* 16/3 */
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 2.67px; /* 8/3 */
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 10.67px; /* 32/3 */
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 18.67px; /* 56/3 */
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 2.67px; /* 8/3 */
      height: 21.33px; /* 64/3 */
    }
    50%, 100% {
      top: 8px; /* 24/3 */
      height: 10.67px; /* 32/3 */
    }
  }  

/* .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
   */